import React, { useState, useEffect } from "react";
import ContentSvg from "../assets/images/svg/content-svg.svg";
import VimeoVideoImg from "../assets/images/vimeo-video-img.png";
import VimeoSvg from "../assets/images/svg/vimeo-svg.svg";
import GallerySvg from "../assets/images/svg/gallery-svg.svg";
import VimeoModal from "./VimeoModal";
import SoundCloudModal from "./SoundCloudVideo";
import ProfileImgSm from "../assets/images/profile-img-sm.png";
import ImageSearch2 from "../assets/images/imagelarge.png";
import Popup from "./PopUp";
import AiBrian from "./AiBrian";
import ChartImg from "../assets/images/chart.png";


const Portfolio = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopupOpen = (e) => {
    e.preventDefault();
    setIsPopupVisible(true);
  };

  const handlePopupClose = (e) => {
    e.preventDefault();
    setIsPopupVisible(false);
  };

  // Vimeo Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Youtube Modal
  const [isModalOpenYoutube, setisModalOpenYoutube] = useState(false);

  const handleOpenModalYoutube = () => {
    setisModalOpenYoutube(true);
  };

  const handleCloseModalYoutube = () => {
    setisModalOpenYoutube(false);
  };

  // Soundcloud Modal
  const [isModalOpenSound, setIsModalOpenSound] = useState(false);

  const handleOpenModalSound = () => {
    setIsModalOpenSound(true);
  };

  const handleCloseModalSound = () => {
    setIsModalOpenSound(false);
  };

  useEffect(() => {
    // Function to initialize fancybox for the gallery

    // Event listener to toggle 'full' class on image click
    const handleImageClick = (event) => {
      event.target.classList.toggle("full");
    };

    // Attach click event to gallery images
    const galleryLinks = document.querySelectorAll(".gallery-link img");
    galleryLinks.forEach((link) => {
      link.addEventListener("click", handleImageClick);
    });

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      galleryLinks.forEach((link) => {
        link.removeEventListener("click", handleImageClick);
      });
    };
  }, []);
  return (
    <>
      {/* <!-- ====================================== Section Portfolio ===================================== --> */}
      <section className="portfolio-section" id="portfolio">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            React Components.
          </h2>
          <div className="line"></div>
        </div>
        <div className="portfolios-group-main">
          <div>
            <div
              className="image-container popup-btn zoom_in"
              onClick={handlePopupOpen}
            >
              <img src={ImageSearch2} alt="content-img" />
              <div className="overlay">
                <img src={ContentSvg} alt="vimeo-svg" className="vimeo-icon" />
                <p className="overlay-text">Image Search</p>
              </div>
            </div>
            <p className="Corporate zoom_in">Image Search</p>
            <p className="Corporate-sub zoom_in">CONTENT</p>
            
          </div>
          <div>
            <div>
              <div
                className="image-container zoom_in"
                id="vimeo"
                onClick={handleOpenModal}
              >
                <img src={ChartImg} alt="chart-img" />
                <div className="overlay">
                  <p className="overlay-text">Dynamic Chart</p>
                </div>
              </div>
              <VimeoModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
              />
            </div>
            <p className="Corporate zoom_in">A Simple Dynamic Chart</p>
            <p className="Corporate-sub zoom_in">Chart Component</p>
            <div>
              <div
                className="second-row-portfolio soundcloud zoom_in"
                onClick={handleOpenModalSound}
              >
                
              </div>
              <SoundCloudModal
                isOpen={isModalOpenSound}
                onRequestClose={handleCloseModalSound}
              />
            </div>
          </div>
          <div className="gallery-bank-section">
            <div className="zoom_in">
              <div className="image-container">
                <a href="#popup2">
                  <img src={ProfileImgSm} alt="gallery-img" />
                  <div className="overlay">
                    <img
                      src={GallerySvg}
                      alt="gallery-svg"
                      className="vimeo-icon"
                    />
                    <p className="overlay-text">Speak with AiBrian</p>
                  </div>
                </a>
              </div>
              <p className="Corporate">Speak with AiBrian</p>
              <p className="Corporate-sub">AI Chatbot</p>
            </div>
            <div
              className="second-row-portfolio popup-btn zoom_in"
              onClick={handlePopupOpen}
            >
              
            </div>
          </div>
        </div>

      </section>
      <Popup isVisible={isPopupVisible} onClose={handlePopupClose} />
      {/* <!-- ====================================== Section Portfolio End ===================================== --> */}
      <div id="popup2" class="popup-container popup-style">
        <div class="popup-content popup-content-gallery">
          <a href="#pop" class="close">
            &times;
          </a>

          <main class="chat">
            <h2 class="mobile_app">AI Chat Componenent</h2>
            <p className="section-sub-text">
              This AI chatbot has been trained to know as much as possible about me as a Developer. It knows about my work history, education,
              and personal certificaitons that I have obtained in order to make me a better developer. It also knows about my hobbies and interests 
              so that it can have a conversation with you about me. It is a great way to learn about me and my work.
            </p>

            <div class="container">
              <AiBrian />

            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default Portfolio;
