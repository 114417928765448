import React, { useEffect } from "react";

const AboutSection = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(
      ".fade_up, .fade_down, .zoom_in, .zoom_out, .fade_right, .fade_left, .flip_left, .flip_right, .flip_up, .flip_down"
    );
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);
  return (
    <>
      {/* <!-- ====================================== Section About ===================================== --> */}
      <section className="About-section jos" id="about">
        <div className="heading-container">
          <h2 className="section-heading-text about-me fade_up">About Me.</h2>
          <div className="line"></div>
        </div>
        <p className="section-sub-text about-sub-text zoom_in">
          Hi, my name is <span>Brian Loriga</span> and I began working as a Web Developer over 11 years ago. 
          I have a passion for creating beautiful, responsive, and user-friendly websites using the latest technologies.
          I have experience in developing websites for small businesses, startups, and large corporations. I am always looking for new challenges and opportunities to grow as a developer.
          Specializing in front-end development, I have a strong understanding of <span>HTML</span>, <span>CSS</span>, <span>JavaScript</span>, <span>Vue.js</span>, <span>React</span>, 
          <span>PhP</span>, <span>Node.js</span>, <span>WordPress</span>, and <span>API developing</span>. 
        </p>
        <div className="about-detail-main">
          <p className="about-detail">Name</p>
          <p className="about-detail-info">Brian Loriga</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Phone</p>
          <p
            className="about-detail-info email"
            onClick="location.href='tel:7867975683'"
          >
            (786) 797-5683
          </p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Email</p>
          <p
            className="about-detail-info email"
            onClick="location.href='mailto:brianmloriga@gmail.com'"
          >
            brianmloriga@gmail.com
          </p>
        </div>
      </section>
      {/* <!-- ====================================== Section About End ===================================== --> */}
    </>
  );
};
export default AboutSection;
