import React, { useState } from "react";
import Modal from "react-modal";
import DataInputGraph from "./Charts";



const VimeoModal = ({ isOpen, onRequestClose }) => {



  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Vimeo Video"
      className="vimeo-modal"
      overlayClassName="vimeo-overlay"
    >
      <div className="popup-box transform-in">
        <a className="close-btn popup-close" href="#" onClick={onRequestClose}>
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M9.16998 14.83L14.83 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M14.83 14.83L9.16998 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </svg>{" "}
        </a>
        <h2 className="mobile_app">Dynamic Chart</h2>
        <p className="section-sub-text">
          This is a simple and dynamic chart that allows users to create data points and the graph will automaticaly adjust when new data in created or removed.
        </p>
        <DataInputGraph />
      </div>
    </Modal>
  );
};

export default VimeoModal;
