import React from "react";
import { useState } from 'react';
import PopUpImg from "../assets/images/pop-up-img1.jpg";
import searchImages from "../assets/javascript/api";
import SearchBar from "./SearchBar";
import ImageList from "./ImageList";
import SearchImage from "../assets/images/search-image-main.png";



const Popup = ({ isVisible, onClose }) => {
  const [images, setImages] = useState([]);

const handleSubmit = async (term) => {
  const result = await searchImages(term);

  setImages(result);
};

  return (
    <div className={`popup-wrap ${isVisible ? "visible" : ""}`}>
      <div className="popup-box transform-in">
        <img className="pop-up-img1" src={SearchImage} alt="pop-up-img1" />
        <a className="close-btn popup-close" href="#" onClick={onClose}>
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M9.16998 14.83L14.83 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M14.83 14.83L9.16998 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </svg>{" "}
        </a>
        <p className="corpo">Search For Any Image</p>
        <SearchBar onSubmit={handleSubmit} />
        <ImageList images={images} />
      </div>
    </div>
  );
};

export default Popup;
