import React, { useEffect, useRef } from "react";
import FigmaImg from "../assets/images/figma-img.png";
import ZapierLogo from "../assets/images/zapier-logo.png";
import ApiLogo from "../assets/images/api-logo.png";
import WordPressLogo from "../assets/images/wordpress-logo.png";
import HubSpotLogo from "../assets/images/hubspot-logo.png";
import PhpImg from "../assets/images/php-img.png";
import JsLogo from "../assets/images/js-logo.png";
import ReactLogo from "../assets/images/react-logo-png.png";
import VueLogo from "../assets/images/vue-logo.png";
const Resume = () => {


  return (
    <>
      {/* <!-- ====================================== Section Education Experience ===================================== --> */}
      <section className="education-experience" id="resume">
        <div className="row">
          <div className="col-xxl-6 col-lg-6">
            <div className="heading-container">
              <h2 className="section-heading-text about-me fade_up">
                Education.
              </h2>
              <div className="line"></div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Bachelors In Business</p>
                <p className="cursus university">
                  Ashworth University
                </p>
                <p className="cursus">
                  Graduated online prior to finding my calling in web development.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">React Certified</p>
                <p className="cursus university">
                  Meta
                </p>
                <p className="cursus">
                  Taken over the course of 3 months directly with Meta. Became certified in React basics and advanced concepts.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Essentialls Of Front End Development</p>
                <p className="cursus university">
                  Meta
                </p>
                <p className="cursus">
                  While I had already been working with the big 3 (HTML, CSS, JS) I wanted to take a course to solidify my knowledge. 
                  This course was taken over the course of 2 months and was a great refresher.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-lg-6">
            <div className="heading-container">
              <h2 className="section-heading-text about-me fade_up">
                Experience.
              </h2>
              <div className="line"></div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Senior Developer</p>
                <p className="cursus university">Peak Seven Advertising / 2023 - Current</p>
                <p className="cursus">
                  In charge of the full development of websites for clients. I work with a team of designers and developers to create custom websites for clients.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Rapid Interviews</p>
                <p className="cursus university">Web Developer / 2021 - 2022</p>
                <p className="cursus">
                  A startup that I worked with to develop their website. I was in charge of the full development of the website. The 
                  website is primiarly built with React and some WordPress componenetnts as well.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">GFN Advertiisng</p>
                <p className="cursus university">Owner / 2016 - 2023</p>
                <p className="cursus">
                  A small web design and development company that I started in 2016. I have worked with over 45 clients to develop custom websites.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Education Experience End ===================================== --> */}
      {/* <!-- ====================================== Section Design Skill ===================================== --> */}
      <section className="design-skill-section">
        <div className="heading-container">
          <h2 className="section-heading-text design-skill-text fade_up">
            Design Skills.
          </h2>
          <div className="line"></div>
        </div>
        <div className="design-skill-sub-section">
          <div className="design-skills-img-main flip_up">
            <img src={FigmaImg} alt="figma-img" />
            <div className="skill-counter-main">
              <p></p>
              <p>HTML, CSS, Tailwind</p>
            </div>
          </div>
          <div className="design-skills-img-main photoshop flip_up">
            <img src={PhpImg} alt="php-img" />
            <div className="skill-counter-main photoshop-text">
              <p></p>
              <p>PHP, WordPress</p>
            </div>
          </div>
          <div className="design-skills-img-main adobe-xd flip_up">
            <img src={JsLogo} alt="javascript-img" />
            <div className="skill-counter-main adobe-xd-text">
              <p></p>
              <p>Javascript, JQuery</p>
            </div>
          </div>

          <div className="design-skills-img-main sketch flip_up">
            <img src={ReactLogo} alt="sktech-img" />
            <div className="skill-counter-main sketch-text">
              <p></p>
              <p>React</p>
            </div>
          </div>
          <div className="design-skills-img-main invision flip_up">
            <img src={VueLogo} alt="vue.js-img" />
            <div className="skill-counter-main invision-text">
              <p></p>
              <p>Vue</p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Coding Skill End ===================================== --> */}
      {/* <!-- ====================================== Section Award ===================================== --> */}
      <section className="awards-section overflow-hidden">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Other Cool Stuff.
          </h2>
          <div className="line"></div>
        </div>
        <div className="row awards-row">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <img
                        className="winner-award"
                        src={ZapierLogo}
                        alt="winner-award4"
                      />
                      <p className="award-yer"></p>
                    </div>
                    <p className="award-interior">Zapier Automation</p>
                    <p className="award-winner-text">Hundres of workflows developed</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                      I have used Zapier to build out hundreds of workflows for clients. This includes chatbots, email automation, complex CRM integrations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <img
                        className="winner-award"
                        src={ApiLogo}
                        alt="winner-award2"
                      />
                      <p className="award-yer"></p>
                    </div>
                    <p className="award-interior">Complex API integrations</p>
                    <p className="award-winner-text">Thousands of lines of data</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                       I have extensive experience integrating complex APIs into various different client projects. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <img
                        className="winner-award"
                        src={WordPressLogo}
                        alt="winner-award3"
                      />
                      <p className="award-yer"></p>
                    </div>
                    <p className="award-interior">WordPress</p>
                    <p className="award-winner-text">Custom Themes & Plugins</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                      I have built many custom WordPress themes and plugins for clients. I have also worked with many clients to maintain and update their WordPress websites.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <img
                        className="winner-award"
                        src={HubSpotLogo}
                        alt="winner-award4"
                      />
                      <p className="award-yer"></p>
                    </div>
                    <p className="award-interior">HubSpot</p>
                    <p className="award-winner-text">CRM Integration</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                      Many of the clients that I work with use HubSpot as their CRM. I have worked with many clients to integrate their website with HubSpot.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Award End ===================================== --> */}
    </>
  );
};
export default Resume;
