import React, { useEffect } from "react";
import ZapierLogo from "../assets/images/zapier-logo.png";
import ApiLogo from "../assets/images/api-logo.png";
import WordPressLogo from "../assets/images/wordpress-logo.png";
import HubSpotLogo from "../assets/images/hubspot-logo.png";
import ApexLogo from "../assets/images/apexwordpress.png";

const Wordpress = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(
      ".fade_up, .fade_down, .zoom_in, .zoom_out, .fade_right, .fade_left, .flip_left, .flip_right, .flip_up, .flip_down"
    );
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);
  return (
    <>
      {/* <!-- ====================================== Section About ===================================== --> */}
      <section className="About-section jos" id="about">
        <div className="heading-container">
          <h2 className="section-heading-text about-me fade_up">
            WordPress Projects
          </h2>
          <div className="line"></div>
        </div>
        <p className="section-sub-text about-sub-text zoom_in">
          Many of my projects that I have worked on in the past year have been
          built on the <span>WordPress</span> platform. All of these sites have
          been built using <span>custom themes</span> or <span>plugins</span>.
          Depending on the needs of the clients I have used
          <span>native blocks</span>, <span>ACF blocks</span>, or just simply
          setting up custom meta boxes or fields for the client to use while the
          front end is controlled entired by the theme. I have also worked on{" "}
          <span>WooCommerce</span> as well as puttin togehter custom backend
          applications for clients to use.
        </p>
      </section>
      {/* <!-- ====================================== Section About End ===================================== --> */}
      {/* <!-- ====================================== Section Education Experience ===================================== --> */}
      <section className="education-experience" id="resume">
        <div className="row">
          <div className="col-xxl-6 col-lg-6">
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <a href="https://apexmarinesales.com/"> 
                  <p className="bachelor">Apex Marine</p>
                </a>
                <p className="cursus university">
                  This Wordpress site was built with a custom theme designed by in house designers. The site brings in Yachts from various API's that
                   need to bee imported and displayed on the site. A script runs daily to make any necessary API calls, bring in new yachts, update
                   existing ones, and remove any that are no longer available.This data is then stored and displayed on the site.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <a href="https://alliedmarine.peakseven.com/">
                <p className="bachelor">Allied Marine</p>
                </a>
                <p className="cursus">
                  This was another Yachting site that brings in Yachts from various API's. The site was built with a custom theme and has a custom plugins. 
                  The site is also integrated with a CRM system that the client uses to manage their leads and customers. The forms on the site will capture
                  all necessary information about the yacht and the page and make various api calls to the CRM system to store the data.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <a href="https://www.uslaw.org/">
                 <p className="bachelor">US Law</p>
                 </a>
                <p className="cursus">
                  This website in unique in that it integrates heavily with Salesforce and brings in attorney and firm data to present in different ways. 
                  There is a also a social media style site built within the main site that allows attorney to communicate with members and post articles.
                  The site also has a various search components that allow new users to find attorneys and firms based on their needs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-lg-6">
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <a href="https://arden.peakseven.com/">
                <p className="bachelor">Arden Communities</p>
                </a>
                <p className="cursus">
                  A lot of credit has to be given to the design team to make this site look as good it does. The video footage was shot with drones and the video showcases
                  new communites being built. The site connects to a backend system that manages new homes being built and I then use that data to display on the site. The websitei
                  is also heavily integrated into a CRM system that can capture leads and assign them to the appropriate sales person.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <a href="https://avenirstaging.peakseven.com/">     
                  <p className="bachelor">Avenir</p>
                 </a>
                <p className="cursus">
                  This is another new community website developed to showcase new homes and ammenities. As with most sites there is a heavy focus on integration with 
                  thrid party systems in order to track and help convert interested parties into leads. The site also uses WooCommerce to sell flair and other items 
                  related to the community itself. 
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <a href="https://join.onelifefitness.com/">
                <p className="bachelor">One Life Fitness</p>
                </a> 
                <p className="cursus">
                  Ok, so this website is not built with WordPress, but I wanted to be on the first page of my portfolio. This site was built with
                  Vue.js and heavily integrated into HubSpot to track leads and conversions. The Vue app integrates heavily with Google Maps API, HubSpot API, 
                  a custom API built by their own internal team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Education Experience End ===================================== --> */}
    </>
  );
};
export default Wordpress;
