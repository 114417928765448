import React, { useEffect, useRef } from 'react';

function AiBrian() {
    const containerRef = useRef(null);

    useEffect(() => {
        (function(d, t) {
            var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
            v.onload = function() {
                window.voiceflow.chat.load({
                    verify: { projectID: '66bb5481127352649dbd40af' },
                    url: 'https://general-runtime.voiceflow.com',
                    versionID: 'production',
                    render: {
                        mode: 'embedded',
                        target: containerRef.current,  // Render into the specific container
                    },
                    autostart: false,
                });
            };
            v.src = "https://cdn.voiceflow.com/widget/bundle.mjs"; 
            v.type = "text/javascript"; 
            s.parentNode.insertBefore(v, s);
        })(document, 'script');
    }, []);

    return (
        <div className="chat-container">
            <div ref={containerRef} className="ai-brian-widget"></div>
        </div>
    );
}

export default AiBrian;
