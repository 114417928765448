import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataInputGraph = () => {
  const [inputData, setInputData] = useState([]);
  const [labels, setLabels] = useState([]);

  const handleInputChange = (e, index) => {
    const newInputData = [...inputData];
    newInputData[index] = e.target.value ? parseFloat(e.target.value) : '';
    setInputData(newInputData);
  };

  const addDataPoint = () => {
    setInputData([...inputData, '']);
    setLabels([...labels, `Point ${labels.length + 1}`]);
  };

  const clearLastDataPoint = () => {
    setInputData(inputData.slice(0, -1));
    setLabels(labels.slice(0, -1));
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'User Input Data',
        data: inputData,
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Dynamic Data Input Graph',
      },
    },
  };

  return (
    <div className="max-w-lg mx-auto p-4 bg-white shadow-lg rounded-lg graph-container">
      <h2 className="text-2xl font-bold text-center mb-4">
        Dynamic Graph Input
      </h2>
      <div className="flex flex-wrap gap-4 data-points-container">
        {inputData.map((data, index) => (
          <div key={index} className="data-point-labels">
            <label className="block text-gray-700 data-text">Point {index + 1}:</label>
            <input
              type="number"
              className="w-full mt-1 p-2 data-input"
              value={data}
              onChange={(e) => handleInputChange(e, index)}
            />
          </div>
        ))}
      </div>
      <div className="button-container mt-4">
        <button
          onClick={addDataPoint}
          className="btn-hover w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 mr-2"
        >
          Add New Data Point
        </button>
        <button
          onClick={clearLastDataPoint}
          className="btn-hover data-button w-full py-2 px-4 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
        >
          Clear Last Data Point
        </button>
      </div>
      <div className="mt-8">
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default DataInputGraph;
