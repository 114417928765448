// Brain.jsx
import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import * as THREE from 'three';  // Import THREE

function AnimatedModel(props) {
    const { scene } = useGLTF('/3dmodels/dragon_glass.glb');
    const modelRef = useRef();

    // Animate the model's entrance
    useFrame(({ clock }) => {
        const elapsedTime = clock.getElapsedTime();
        if (modelRef.current) {
          modelRef.current.rotation.y = elapsedTime * 0.3; 
        }
    });

    return <primitive ref={modelRef} object={scene} {...props} />;
}

function FitCamera({ modelRef }) {
    const { camera, gl: { domElement } } = useThree();

    useEffect(() => {
        if (modelRef.current) {
            const box = new THREE.Box3().setFromObject(modelRef.current);
            const size = box.getSize(new THREE.Vector3());
            const center = box.getCenter(new THREE.Vector3());

            const maxDim = Math.max(size.x, size.y, size.z);
            const fov = camera.fov * (Math.PI / 180);
            let cameraZ = Math.abs(maxDim / 4 * Math.tan(fov * 2));

            camera.position.z = center.z + cameraZ;
            camera.lookAt(center);

            const controls = new OrbitControls(camera, domElement);
            controls.target.set(center.x, center.y, center.z);
            controls.update();
        }
    }, [modelRef, camera, domElement]);

    return null;
}

export default function App() {
  return (
    <Canvas
    camera={{ position: [0, 2, 32] }}
    style={{ background: 'linear-gradient(135deg, #5b3375, #2091b4)' }} 
    className='brain'
>
           <ambientLight intensity={0.3} />
            <pointLight position={[10, 10, 10]} intensity={1} />
            <pointLight position={[-10, -10, -10]} intensity={1} color="purple" />
            <pointLight position={[0, 10, -10]} intensity={1} color="blue" />
            <AnimatedModel position={[0, 0, 0]} />
          <OrbitControls />
      </Canvas>
  );
}
