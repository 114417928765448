import { useState } from "react";

function SearchBar({ onSubmit }) {
  const [term, setTerm] = useState("");

  const handleFormSubmit = (event) => {
    event.preventDefault();

    onSubmit(term);
  };

  const handleChange = (event) => {
    setTerm(event.target.value);
  };

  return (
    <div className="search-input">
      <form onSubmit={handleFormSubmit}>
        <input
          className="form__input search-input"
          value={term}
          onChange={handleChange}
          placeholder="Enter search term"
        />
      </form>
    </div>
  );
}

export default SearchBar;
